import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@material-ui/core/Box";
import ContactForm from "./ContactForm"
import { FC, Key } from "react";
import AppBars from "./AppBar";
import { Link } from "react-scroll";

export default function CardLayout() {
    return (
        <>
            <Box>
                <AppBars />
                {/* borderRadius: '0px' removes inherent styling MUI provides to Cards */}
                <Card style={{ borderRadius: '0px' }}>
                    <CardMedia
                        component="img"
                        image="/Softex.webp"
                        alt="SoftExPic"
                        style={{ maxHeight: '350px' }}
                    />
                </Card >
            </Box>
            <Box p="15px">
                {/* Negative marginTop overlays content start on landing image */}
                <Box mt="-50px">
                    <Box gridGap="6px" display={"grid"} gridAutoFlow={"row"} gridAutoColumns={"repeat(2, 50vw)"}>
                        <Founder />
                        <About />
                        <Box id="Cisco">
                            <CiscoPhonesWithServices />
                        </Box>
                        <Box id="Teams">
                            <ContentCard
                                title="Microsoft Teams"
                                src="/Teams.webp"
                                alt="Teams Voice & Video"
                                subHeading="Microsoft Teams has become the standard in collaboration / conferencing, and has simplified our needs for remote working. 
                            Command Solutions are skilled in configuring Teams with features like:"
                                text={parseArray(teamsText)}
                            />
                        </Box>
                        <Box id="Soft-Ex">
                            <ContentCard
                                title="Soft-Ex"
                                src="/SoftexFull.webp"
                                alt="Soft-Ex Call Management Software"
                                subHeading="The Soft-ex Product Suite creates a dashboard that allows any company to easily see their Telecom spend per month. The benefits include:"
                                text={parseArray(softExText)}
                            />
                        </Box>
                        <ContactForm />
                    </Box>
                </Box>
            </Box>
        </>
    )
};

interface CardProps {
    src?: string,
    alt?: string,
    title: string,
    subHeading?: string,
    text?: string[],
};

const ContentCard: FC<CardProps> = ({ src, alt, title, subHeading, text }) => (
    <Box>
        <Card>
            <CardMedia
                component="img"
                height="140"
                image={src}
                alt={alt}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" fontWeight={500}>
                    {title}
                </Typography>
                <Typography style={{ paddingBottom: "4px" }}>
                    {subHeading}
                </Typography>
                {text}
            </CardContent>
        </Card >
    </Box>
);

const CiscoPhonesWithServices: FC = () => (
    <Box>
        <Card>
            <Box display={"flex"} justifyContent={"flex-start"}>
                <Box display={"flex"} justifyContent={"flex-start"}>
                    <Typography variant="h5" p="0.9rem" fontWeight={500}>
                        IT services we provide:
                    </Typography>
                </Box>
                <Box
                    display={"flex"}
                    sx={{
                        display:
                        {
                            xs: 'none',
                            sm: 'flex',
                            md: 'flex',
                            lg: 'flex',
                            xl: 'flex'
                        }
                    }}
                    style={{
                        alignItems:
                            'center',
                        paddingLeft: '0.2rem',
                        paddingRight: '0.5rem'
                    }}>
                    <Link
                        to="Cisco"
                        style={{ textDecoration: 'none', color: 'darkBlue' }}
                        smooth={true}
                        duration={1000}
                        isDynamic={true}>
                        <Typography
                            fontSize={22}
                            pl="1rem"
                            fontWeight={500}
                            style={{ color: "darkBlue" }}>
                            Cisco
                        </Typography>
                    </Link>
                    <Link
                        to="Teams"
                        style={{ textDecoration: 'none', color: 'darkBlue' }}
                        smooth={true}
                        duration={1000}
                        isDynamic={true}>
                        <Typography
                            fontSize={22}
                            pl="1rem"
                            fontWeight={500}
                            style={{ color: "darkBlue" }}>
                            MS Teams
                        </Typography>
                    </Link>
                    <Link to="Soft-Ex"
                        style={{ textDecoration: 'none', color: 'darkBlue' }}
                        smooth={true}
                        duration={1000}
                        isDynamic={true}
                    >
                        <Typography fontSize={22} pl="1rem" fontWeight={500} style={{ color: "darkBlue" }}>
                            Soft-Ex
                        </Typography>
                    </Link>
                </Box>
            </Box>
            <CardMedia
                component="img"
                height="140"
                image="/Cisco.webp"
                alt="Cisco Phones"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" fontWeight={500}>
                    Cisco Phones
                </Typography>
                <Typography style={{ paddingBottom: "4px" }}>
                    Cisco Collaboration has been the leader in the voice / video market, and is suited for both on-prem and remote working.
                    Command Solutions have deployed sites with:
                </Typography>
                {parseArray(ciscoText)}
            </CardContent>
        </Card >
    </Box>
);

interface DotPointProps {
    text: string | [],
}

const parseArray = (arr: any) => (
    arr.map((el: [], index?: Key) =>
        <CardDotPoint key={index} text={el} />
    )
);

const CardDotPoint: FC<DotPointProps> = ({ text }) => (
    <Box pt="2px">
        <Typography variant="body2" pt="4px">
            {/* Negative margin ensures icons are inline with content */}
            <ArrowRightIcon style={{ marginBottom: "-7px", marginLeft: "-9px", marginRight: "-2px" }} />{text}
        </Typography>
    </Box>
);

const Founder: FC = () => (
    <Box id="About">
        <Card style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
            <CardContent style={{ background: 'linear-gradient(180deg, rgba(148,173,218,0.3) 0%, rgba(255,255,255,0.8) 37%)' }}>
                <Typography gutterBottom variant="h5" component="div" fontWeight={500}>
                    Gary Bates
                </Typography>
                <Typography variant="body1" pb="10px">
                    Manager and founder of Command Solutions. A collaboration specialist with industry-recognised certificates from:
                </Typography>
                <CardDotPoint text="Cisco (CCIE in Collaboration)" />
                <CardDotPoint text="Microsoft Teams (MS-700, 720)" />
                <CardDotPoint text="Microsoft Azure (AZ-900)" />
                <CardDotPoint text="Soft-Ex Analytics (Level 3)" />
            </CardContent>
        </Card>
    </Box>
);

const About: FC = () => (
    <Box>
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" fontWeight={500}>
                    About
                </Typography>
                <Typography variant="body1" pb="14px">
                    Founded in 2004, we focus on delivering high quality voice and video solutions
                    to the Government. Our office is based in Canberra, working closely with many agencies across the ACT. Our packages include:
                </Typography>
                <CardDotPoint text="Ongoing support and training, with pre-purchasing available and no hidden costs." />
                <CardDotPoint text="Daily training for IT support desks (Level 1 and 2)" />
            </CardContent>
        </Card>
    </Box>
);

// Text and image source
const teamsText: Array<string> = [
    "Video calling and screen sharing", "Audio conferencing", "Basic and extended telephony features", "Azure AD Connect for directory synchronisation"
];

const softExText: Array<string> = [
    "Department heads can view a breakdown of their costs",
    "Manager's can view each delivery technology expenditure (VoIP, mobile or fixed phones), or across the least cost routing options",
    "CIO’s can reduce their ongoing costs in order to make their company more profitable"
];

const ciscoText: Array<string> = [
    "Cisco Jabber, desk phones, webex calling and switchboards",
    "Small (<100 users) to Medium (> 100 users) contact centres with omnichannel",
    "Webex conferencing including video boards with interactive displays & file sharing",
    "Simple (one camera) & complex rooms with multiple cameras (presenter tracking, etc)",
    "Workforce management and quality monitoring for recording",
    "Remote Jabber softphones using Expressways MRA"
];
import Box, { BoxProps } from "@material-ui/core/Box";
import { TextField } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from '@mui/material/Button';
import Send from '@mui/icons-material/Send';


function Item({ sx, ...other }: BoxProps) {
    return (
        <Box
            sx={{
                p: 0,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }} {...other}
        />
    );
};

const ContactForm = () => {
    return (
        <Item id="Contact">
            <Card>
                <Typography
                    variant="h5"
                    p="1rem"
                    fontWeight={500}
                >
                    Contact:
                </Typography>
                <form id="fs-frm" name="contact-form" acceptCharset="utf-8" action="https://formspree.io/f/mlezgogb" method="post">
                    <FormControl style={{ display: 'flex', justifyContent: 'center' }} >
                        <Box>
                            <Box style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: '20px', padding: '8px' }}>
                                <TextField
                                    required
                                    label='Email'
                                    id='outlined-basic'
                                    variant="outlined"
                                    color="primary"
                                    style={{ minWidth: "35vw" }}
                                    inputProps={{ maxLength: 60 }}
                                    type="email"
                                    name="Email"
                                />
                                <TextField
                                    required
                                    label='Message'
                                    id='outlined-basic'
                                    variant="outlined"
                                    color="primary"
                                    style={{ minWidth: "35vw" }}
                                    inputProps={{ maxLength: 160 }}
                                    name="Message"
                                />
                            </Box>
                            <Box
                                display="flex" justifyContent="center">
                                <Button
                                    sx={{
                                        mt: '2rem',
                                        mb: '2rem',
                                        backgroundColor: "#000",
                                        width: "10rem"
                                    }}
                                    variant='contained'
                                    endIcon={<Send />}
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </FormControl>
                </form>
            </Card >
        </Item>
    )
};

export default ContactForm
import { Box, ListSubheader, Slide, useScrollTrigger } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-scroll";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useState } from "react";

function HideOnScroll(props: Props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

export default function AppBars() {
    const [navPlaceholder, setNavPlaceholder] = useState<string>("Navigate to");
    const [open, setOpen] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false)
    };

    const handleOpen = () => {
        setOpen(true)
    };

    // Link href will always equal name, thus one prop serves both
    function NavLink({ name, value }: NavLinkProps) {
        return (
            <Link
                to={name}
                style={{ textDecoration: "none", color: "black" }}
                smooth={true}
                duration={1000}
                isDynamic={true}
            >
                <MenuItem
                    value={value}
                    onClick={() => {
                        handleClose();
                        setNavPlaceholder(name);
                    }}>
                    {name}
                </MenuItem>
            </Link>
        )
    }

    return (
        <Box>
            <ThemeProvider theme={theme}>
                <HideOnScroll>
                    <AppBar
                        position="fixed"
                        elevation={2}
                        sx={{
                            width: "96%",
                            borderRadius: "14px",
                            backgroundColor: "white",
                            marginTop: "20px",
                            marginRight: "2vw"
                        }}>
                        <Box style={{
                            display: "inline-flex",
                            justifyContent: "space-between"
                        }}>
                            <Typography
                                variant="h5"
                                color="black"
                                marginLeft="0.6rem"
                                p="0.6rem"
                                fontWeight={500}
                                style={{ opacity: 1 }}
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                Command Solutions
                            </Typography>
                            <FormControl variant="standard" sx={{
                                p: 0.5,
                                minWidth: 130,
                                marginRight: "20px",
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <Select
                                    onOpen={handleOpen}
                                    onClose={handleClose}
                                    open={open}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label", MenuProps: { disableScrollLock: true } }}
                                    renderValue={() =>
                                        <Placeholder text={navPlaceholder} />
                                    }>
                                    <NavLink name="About" value={20} />
                                    <ListSubheader>
                                        Services
                                    </ListSubheader>
                                    <NavLink name="Cisco" value={30} />
                                    <NavLink name="Teams" value={40} />
                                    <NavLink name="Soft-Ex" value={50} />
                                    <ListSubheader>
                                        Contact
                                    </ListSubheader>
                                    <NavLink name="Contact" value={60} />
                                </Select>
                            </FormControl>
                        </Box>
                    </AppBar>
                </HideOnScroll>
            </ThemeProvider>
        </Box>
    );
};

interface NavLinkProps {
    name: string,
    value: number
}

// Adds a permanent placeholder if event.target.value state is not updated
interface PlaceholderProps {
    text: string,
}

function Placeholder({ text }: PlaceholderProps) {
    return (
        <div style={{ color: "#000", paddingLeft: "4px" }}>
            {text}
        </div>
    )
};


const theme = createTheme();

theme.typography.h5 = {
    fontSize: "1rem", //small screens
    "@media (min-width:600px)": {
        fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "1.7rem",
        fontWeight: "500",
    },
};

interface Props {
    window?: () => Window;
    children: React.ReactElement;
};
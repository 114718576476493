import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import CardLayout from "./components/CardLayout";
import { blue } from "@mui/material/colors";
import "typeface-roboto";
import "typeface-cormorant";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: blue,
  },
  typography: {
    h3: {
      fontFamily: "Noto Serif, serif",
      fontSize: 40,
    },
    h6: {
      fontFamily: "Noto Serif, serif",
      fontSize: 26,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: "#000e24" },
        }}
      />
      <CardLayout />
    </ThemeProvider>
  );
}

export default App;
